<template>

  <div>
    <!-- боковая панель чека -->
    <profile-basket-side-bar
      v-if="ticket"
      :is-profile-basket-side-bar-active.sync="isProfileBasketSideBarActive"
      :ticket="ticket"
      :open-element-id="ticket.id"
      :is-admin="isAdmin"
    />
    <!-- *** -->

    <b-card>
      <b-row class="mb-1">
        <b-col>
          <h3>Список товаров</h3>
        </b-col>
      </b-row>

      <b-row class="align-items-center mb-1">
        <b-col
          xl="3"
          lg="3"
          md="6"
          sm="6"
        >

          <v-select
            v-model="byMultibasketId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="basketList"
            class="w-100"
            :reduce="val => val.id"
            placeholder="выбрать корзину"
            @input="refetch(byMultibasketId)"
          />

        </b-col>
        <b-col class="d-flex justify-content-end">
          <add-ticket-manual-button
            v-if="ticket && ticket.id"
            class="mr-1"
            :basket-ticket-id="ticket.id"
          />
          <b-button
            v-if="ticket"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-1"
            variant="primary"
            @click="doCopy(ticket)"
          >
            <feather-icon icon="ClipboardIcon" />
            <span class="ml-25 align-middle">QR-чека (стр)</span>
          </b-button>

          <b-button
            v-if="ticket"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="isProfileBasketSideBarActive = true"
          >
            <span class="text-nowrap">
              <feather-icon
                :icon="ticket.companyId ? 'CheckIcon' : 'SlashIcon'"
                class="mr-50 text-white"
              />
            </span>
            <span class="text-white mb-0">
              Чек № {{ ticket.fiscalDocumentNumber }}
            </span>
          </b-button>
        </b-col>
      </b-row>

      <b-table
        title="Состав корзины"
        show-empty
        :fields="Columns"
        :items="itemsList"
        empty-text="Корзина пуста"
      />
    </b-card>
  </div>

</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BCard,
  BButton,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import router from '@/router'
import { userProfileBaskets, getBasketById } from '@/application/basketService'
import ability from '@/libs/acl/ability'
import ProfileBasketSideBar from '@/components/basket/ProfileBasketSideBar.vue'
import { clearRReceiveDate, clearSumm } from '@/utils/UserFilter'
import AddTicketManualButton from '@/components/basket/AddTicketManualButton.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BButton,
    vSelect,

    ProfileBasketSideBar,
    AddTicketManualButton,
  },

  data() {
    return {
      Columns: [
        { key: 'articul', label: 'артикул' },
        { key: 'brand', label: 'бренд' },
        { key: 'title', label: 'товар' },
      ],

      isProfileBasketSideBarActive: false,
    }
  },
  methods: {
    doCopy(basketTicket) {
      this.$copyText(`t=${clearRReceiveDate(basketTicket.receiveDate)}&s=${clearSumm(basketTicket.totalSum)}&fn=${basketTicket.fiscalDriveNumber}&i=${basketTicket.fiscalDocumentNumber}&fp=${basketTicket.fiscalSign}&n=${basketTicket.operationType}`).then(() => this.$store.commit('app/SET_MESSAGE', 'Скопировано!'), () => this.$store.commit('app/SET_ERROR', 'Ошибка при копировании'))
    },
  },
  setup() {
    const userId = parseInt(router.currentRoute.params.id, 10)
    const basketList = ref([])
    const byMultibasketId = ref(null)
    const ticket = ref(null)
    const itemsList = ref([])
    const isAdmin = ref(!!ability.can('manage', 'all'))

    const refetch = id => {
      if (id) {
        getBasketById(id).then(res => {
          const { items, basketTicket } = res

          if (basketTicket) {
            ticket.value = basketTicket
          } else {
            ticket.value = null
          }
          itemsList.value = items
        })
      }
    }

    userProfileBaskets({ userId }).then(res => {
      const { list, defaultBasketId } = res

      basketList.value = list

      byMultibasketId.value = defaultBasketId.value
      refetch(defaultBasketId.value)
    })

    return {
      basketList,
      byMultibasketId,
      ticket,
      itemsList,
      refetch,
      isAdmin,

      clearRReceiveDate,
      clearSumm,
    }
  },
}
</script>
